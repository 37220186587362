import React from "react";
import metrics from "../../assets/image/forms/metrics.png";


const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-lg-10 align-items-center justify-content-center">
          <div
            className="col-xl-4 col-lg-4 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={metrics} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-8 col-lg-8 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2 Forms">
              Grow Sales &amp; SignUps
              </h2>
              <p className="font-size-6 mb-0">
                Popups are a sure way to entice potential customers and get them to convert. Offer them discounts and promotions to increase your conversion.
              </p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Increase email Sign ups by up to <strong>74%</strong></td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Reduce cart abandonment by up to <strong>63%</strong></td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Targeted promotions can increase sales up to <strong>82%</strong></td>
              </tr>                                                       
            </table>              
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
