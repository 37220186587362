import React from "react";
import templates from "../../assets/image/pop-templates.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row mt-10 align-items-center justify-content-center">
          <div
            className="order-lg-1 order-2 col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2 Forms">
                Pre-built or custom.<br />You choose.
              </h2>
              <p className="font-size-6 mb-5">
              A plethora of professionally designed pre-built templates to choose or simply build your own.
              </p>
              <p>Enjoy our range of plugins to create eye-catching popups</p>
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Pre-built templates</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Pre-built capture forms</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Animated popups</td>
              </tr>                
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td>Use our royalty free images</td>
              </tr>                                        
            </table>                
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
          <div
            className="order-lg-2 order-1 col-xl-6 col-lg-6 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={templates} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
