import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PageWrapper from "../components/PageWrapper";
import HeroNew from "../sections/popups/Content3";
import Commit from "../sections/common/Commit";
import Pricing from "../sections/home4/Pricing";
import WaveReverse from "../sections/common/WaveReverse";
import FeatureOne from "../sections/popups/FeatureOne";
import FeatureTwo from "../sections/popups/FeatureTwo";
import FeatureThree from "../sections/popups/FeatureThree";
 
import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/meta-forms.jpg'

const Demo = () => {  
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          version: 'popups-version',
          footerStyle: "digma",
        }}>
        <SEO
          title="Digma Popups - increase sales &amp; drive conversions"
          description="Build your email marketing list, promote offers, collect feedback, upsell and cross-sell and improve your customers journey"
          image={metaImg}
        />         
        <HeroNew />
        <WaveReverse background="#fff" color="#F7F9FC" />  
        <FeatureOne className="grey pt-0 pb-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />         
        <FeatureTwo className="pt-0 pb-0" />
        <WaveReverse background="#fff" color="#F7F9FC" />         
        <FeatureThree className="grey pt-0" />
        <WaveReverse background="#F7F9FC" color="#fff" />       
        <Pricing />
        <div className='d-none d-md-block text-center pb-10 pt-5'>
        <AniLink className="font-size-7" paintDrip hex="#2196f3" to="/pricing">
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>
        

      </PageWrapper>
    </>
  );
};
export default Demo;
