import React from "react";
import embed from "../../assets/image/target.png";

const ContentTwo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row pt-10 align-items-center justify-content-center">
          <div
            className="col-xl-5 col-lg-5 col-xs-12"
            data-aos="fade-right"
            data-aos-delay={100}
          >
            <div className="mt-5 mt-lg-0">
              <div className="img-2">
                <img className="rounded-10 w-100" src={embed} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xl-7 col-lg-7 col-xs-12"
            data-aos="fade-left"
            data-aos-delay={100}
          >
            <div className="pl-lg-15 pt-11 pt-lg-0">
              <h2 className="font-size-10 mb-2 Forms">
                Targeting<br />at the right moment
              </h2>
              <p className="font-size-6 mb-0">
              Show the right message to the right person at the right time.
              </p>    
              <p className="font-size-6 mb-0">
                Target your popups in different ways.
              </p>                        
              <table className="mt-5 text-black" cellPadding="5">
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td><strong>Location</strong> - Target visitors by city, state, country, and more.</td>
              </tr>
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td><strong>Campaign</strong> - Target visitors by ad, campaign, source, medium, and more.</td>
              </tr>  
              <tr>
                <td><i className="fa fa-check mr-4 bg-form border-0 text-white" /></td>
                <td><strong>Date</strong> - Schedule messaging and offers by time, date, returning visitors, and more.</td>
              </tr>                                                                   
            </table>                     
              <div className="mt-9">
                <a className="btn bg-form text-white btn-xl h-55 rounded-5" href="https://app.digma.io?mode=login/">
                try for free
                </a>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
